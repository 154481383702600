import React from "react";

//STYLES
import * as Styles from "./styles";

function MeetTheTeam() {
    return (
        <div
            style={{
                border: "1px solid black",
                height: "100vh",
                width: "100vw",
                backgroundImage: `url('/content/mainStyle/homeSections/backgroundImages/image2.jpeg')`,
                backgroundSize: "cover"
            }}
        >
            <div style={{ ...Styles.mainBody, height: "100vh", backgroundColor: "rgba(0,0,255,0.5)" }}>
                <h1>Meet The Team</h1>
            </div>
        </div>
    );
}

export default MeetTheTeam;

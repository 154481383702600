export const navBar = {
    position: "absolute"
    // right: "0"
};

export const bodies = {
    position: "absolute",
    zIndex: "-1",
    transition: "all 1.5s ease-out"
};

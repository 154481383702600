import React from "react";
import { Image } from "react-bootstrap";

//IMAGES
import MainPhoto from "./images/Main Photo.png";

function Home() {
    return (
        <div
            style={{
                border: "1px solid black",
                height: "100vh",
                width: "100vw",
                backgroundImage: `url('/content/mainStyle/homeSections/backgroundImages/image1.jpg')`,
                backgroundSize: "cover"
            }}
        >
            <div
                style={{
                    height: "100vh",
                    backgroundColor: "rgba(0,0,255,0.5)"
                }}
            >
                <div style={{ color: "white", fontSize: "5vw", fontWeight: "bold", textAlign: "center", paddingTop: "10vh" }}>
                    Welcome To Penycae Neighbourhood Church <br /> of the Nazarene
                    <br />
                    <Image src={MainPhoto} style={{ width: "40vw", height: "50vh", margin: "20px" }} />
                </div>
            </div>
        </div>
    );
}

export default Home;

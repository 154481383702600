export const GS_UPDATE_MAINCONTACTPAGE = "GS_UPDATE_MAINCONTACTPAGE";
export const GS_UPDATE_SITE_OFFLINE = "GS_UPDATE_SITE_OFFLINE";
export const GS_UPDATE_SITE_OFFLINE_MESSAGE = "GS_UPDATE_SITE_OFFLINE_MESSAGE";
export const GS_ISMOBILE = "GS_ISMOBILE";

export const UpdateMainContactPageVisible = (val) => {
    return {
        type: GS_UPDATE_MAINCONTACTPAGE,
        value: val
    };
};

export const UpdateSiteOffline = (val) => {
    return {
        type: GS_UPDATE_SITE_OFFLINE,
        value: val
    };
};

export const UpdateSiteOfflineMessage = (val) => {
    return {
        type: GS_UPDATE_SITE_OFFLINE_MESSAGE,
        value: val
    };
};

export const UpdateIsMobile = (val) => {
    return {
        type: GS_ISMOBILE,
        value: val
    };
};

import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";

//STYLES
import * as NavBarStyles from "./styles";

function NavBar(props) {
    const GS_generalSettings = useSelector((state) => state.GS_generalSettings);

    const [hover, setHover] = useState({
        home: false,
        meetTheTeam: false,
        servicesAndEvents: false,
        community: false,
        links: false,
        contactUs: false
    });
    const [mobileNarBarShow, setMobileNavBarShow] = useState(false);

    function handleMouseEnter(event) {
        const { id } = event.target;

        setHover((prevState) => {
            return { ...prevState, [id]: true };
        });
    }

    function handleMouseLeave(event) {
        const { id } = event.target;

        setHover((prevState) => {
            return { ...prevState, [id]: false };
        });
    }

    function toggleMobileMenu() {
        if (!mobileNarBarShow) {
            setMobileNavBarShow(true);
        } else {
            setMobileNavBarShow(false);
        }
    }

    function setPositionFromMobileNav(position) {
        props.setSitePosition(position);
        setMobileNavBarShow(false);
    }

    const browserNavBar = (
        <div style={NavBarStyles.sideNavBar}>
            <div
                style={hover.home ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="home"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(0)}
            >
                Home
            </div>
            <div
                style={hover.meetTheTeam ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="meetTheTeam"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-100)}
            >
                Meet The Team
            </div>
            <div
                style={hover.servicesAndEvents ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="servicesAndEvents"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-200)}
            >
                Services and Events
            </div>
            <div
                style={hover.community ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="community"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-300)}
            >
                Community
            </div>
            <div
                style={hover.links ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="links"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-400)}
            >
                Links
            </div>
            <div
                style={hover.contactUs ? NavBarStyles.navButtonHover : NavBarStyles.navButton}
                id="contactUs"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => props.setSitePosition(-500)}
            >
                Contact Us
            </div>
        </div>
    );

    const mobileNavBar = (
        <div>
            <div style={NavBarStyles.topNavBar}>
                <div style={NavBarStyles.topBar} onClick={toggleMobileMenu}>
                    <div style={NavBarStyles.topBarIcon}>
                        <i className="fa-solid fa-bars fa-2xl"></i>
                    </div>
                </div>
            </div>
            {mobileNarBarShow && (
                <div style={NavBarStyles.mobileNav_navBody}>
                    <div style={{ ...NavBarStyles.mobileNav_navButton, borderRadius: "25px 0 0 0" }} onClick={setPositionFromMobileNav.bind(this, 0)}>
                        Home
                    </div>
                    <div style={NavBarStyles.mobileNav_navButton} onClick={setPositionFromMobileNav.bind(this, -100)}>
                        Meet the Team
                    </div>
                    <div style={NavBarStyles.mobileNav_navButton} onClick={setPositionFromMobileNav.bind(this, -200)}>
                        Services and Events
                    </div>
                    <div style={NavBarStyles.mobileNav_navButton} onClick={setPositionFromMobileNav.bind(this, -300)}>
                        Community
                    </div>
                    <div style={NavBarStyles.mobileNav_navButton} onClick={setPositionFromMobileNav.bind(this, -400)}>
                        Links
                    </div>
                    <div
                        style={{ ...NavBarStyles.mobileNav_navButton, borderRadius: "0 0 0 25px" }}
                        onClick={setPositionFromMobileNav.bind(this, -500)}
                    >
                        Contact Us
                    </div>
                </div>
            )}
        </div>
    );

    return <div style={NavBarStyles.body}>{GS_generalSettings.isMobile ? mobileNavBar : browserNavBar}</div>;
}

export default NavBar;

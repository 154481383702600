import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useDispatch } from "react-redux";

//ACTIONS
import * as UserActions from "../../../../../store/actions/user";

//STYLES
import * as WebsiterViewerStyles from "../styles/websiteViewer";

//COMPONENTS
import HighViewStudios from "../webStyles/highViewStudios/pages/userRouteSystem";
import StGiles from "../webStyles/stGiles/pages/userRouteSystem";
import Nazarene from "../webStyles/nazarene/pages/userRouteSystem";

function WebsiterViewer() {
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function handleLogOut() {
        Axios.post("/userLogin/logout")
            .then((res) => {
                const data = res.data;
                if (data.message === "User logged out") {
                    dispatch(UserActions.UpdateAuth(false));
                    dispatch(UserActions.UpdateID(""));
                    dispatch(UserActions.UpdateName(""));
                    dispatch(UserActions.UpdateEmail(""));
                    dispatch(UserActions.UpdateType(""));

                    navigate("/membership/login");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div>
            <div style={WebsiterViewerStyles.banner}>
                <Row>
                    <Col style={WebsiterViewerStyles.columnLeft}>Styling Portal: this banner is not part of your style</Col>
                    <Col style={WebsiterViewerStyles.columnRight}>
                        <Button style={WebsiterViewerStyles.loginBtns} variant="warning" onClick={handleLogOut}>
                            Log Out
                        </Button>
                    </Col>
                </Row>
            </div>
            {params.tag == "highViewStudios" && <HighViewStudios />}
            {params.tag == "stGiles" && <StGiles />}
            {params.tag == "nazarene" && <Nazarene />}
        </div>
    );
}

export default WebsiterViewer;

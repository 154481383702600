import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { isBrowser } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import Axios from "axios";
import { Row, Col, Image } from "react-bootstrap";

import "./styles/customCSSStyles.css";

//ACTIONS
import * as GS_generalSettingsActions from "../../../../../../../store/actions/globalSettings/GS_generalSettings";

//STYLES
import * as UserRouteSystemStyles from "./styles/userRouteSystem";

//USER SIDE
// import BrowserNavBar from "../pods/special/navBar/browserNavBar";
// // import MobileNavBar from "../pods/special/navBar/mobileNavBar";

//PAGES
import HomePage from "./files/home";

//FROM ADMIN CORE SIDE
import AdminBanner from "../../../../../../../administration/pods/adminBanner/adminBanner";
import SiteOffline from "../../../../../../../administration/pods/siteOffline/files/siteOffline";

function UserRouteSystem() {
    const user = useSelector((state) => state.user);

    const [isLoaded, setIsLoaded] = useState(false);
    const [isOffline, setIsOffline] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoaded(true);
        if (window.innerWidth < 740) {
            dispatch(GS_generalSettingsActions.UpdateIsMobile(true));
        } else {
            dispatch(GS_generalSettingsActions.UpdateIsMobile(false));
        }

        window.addEventListener("resize", (event) => {
            if (event.target.innerWidth < 740) {
                dispatch(GS_generalSettingsActions.UpdateIsMobile(true));
            } else {
                dispatch(GS_generalSettingsActions.UpdateIsMobile(false));
            }
        });
    }, []);

    // function onOpen() {
    //     Axios.post("/pods/userRouter/getOpeningData")
    //         .then((res) => {
    //             const data = res.data;
    //             console.log(data);
    //             dispatch(GS_generalSettings.UpdateMainContactPageVisible(data.settings.mainContactPage.value == "true"));
    //             dispatch(GS_generalSettings.UpdateSiteOffline(data.settings.siteOffline.value == "true"));
    //             dispatch(GS_generalSettings.UpdateSiteOfflineMessage(data.settings.siteOffline.subValue));
    //             setIsOffline(data.settings.siteOffline.value == "true");

    //             if (user.auth && user.type !== "registered") {
    //                 dispatch(UserActions.UpdateAdminSignedIn(true));
    //             }

    //             //MEMBERSHIP EXTRA POD
    //             if (user.requestedPassword) {
    //                 navigate("/membership/changePassword");
    //             }

    //             setIsLoaded(true);
    //         })
    //         .catch((err) => console.log(err));
    // }

    function offlineUserCheck(role) {
        if (role == "modifier" || role == "registered" || role == "") {
            return false;
        } else {
            return true;
        }
    }

    return (
        <div>
            {isLoaded && (
                <div>
                    {isOffline && !offlineUserCheck(user.type) ? (
                        <div>
                            <SiteOffline />
                        </div>
                    ) : (
                        <div>
                            {user.adminSignedIn ? <AdminBanner /> : null}
                            <HomePage />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default UserRouteSystem;

import * as Colors from "../../../../globalStyles/administratorColors";

export const banner = {
    top: "0",
    backgroundColor: Colors.primary,
    width: "100vw",
    height: "50px"
};

export const columnRight = {
    textAlign: "right",
    paddingRight: "30px"
};

export const columnLeft = {
    paddingLeft: "30px",
    color: "white",
    display: "flex",
    flexDirection: "row",
    flex: "1",
    alignItems: "center"
};

export const loginBtns = {
    color: Colors.accent,
    fontWeight: "bold"
};

export const body = {
    // height: "100vh",
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center"
};

export const navButton = {
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    fontSize: "20px",
    borderRadius: "10px 0 0 10px",
    cursor: "pointer",
    width: "220px"
};

export const navButtonHover = {
    backgroundColor: "white",
    marginTop: "5px",
    marginBottom: "5px",
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "10px",
    paddingLeft: "10px",
    fontSize: "20px",
    borderRadius: "10px 0 0 10px",
    cursor: "pointer",
    fontWeight: "bold"
};

export const sideNavBar = {
    position: "absolute",
    right: "0",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
};

export const topNavBar = {
    // height: "30px",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "end"
    // padding: "10px"
};

export const topBarIcon = {
    color: "white",
    // border: "1px solid black",
    display: "flex",
    justifyContent: "center"
};

export const topBar = {
    height: "30px",
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    // border: "1px solid black"
    // backgroundColor: "red"
};

//mobile nav bar
export const mobileNav_navButton = {
    height: "50px",
    width: "170px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    paddingRight: "20px"
};

export const mobileNav_navBody = {
    position: "absolute",
    top: "50",
    right: "0",
    borderRadius: "25px"
};
